export const setColumnWidth = (col: keyof CampaignsType | string) => {
  switch (col) {
    case "display_name":
    case "brand_name":
      return "w-[248px]";
    case "status":
    case "metrics":
      return "w-[156px]";
    case "data_activation":
    case "platforms":
      return "w-[204px]";
    case "total_spend":
    case "report":
      return "w-[148px]";
    case "start_date":
    case "end_date":
      return "w-[168px]";
    case "actions":
      return "w-[20px]";
    default:
      return "w-[100px]";
  }
};

export const beautifyStatus = (status: string) => {
  switch (status) {
    case "draft":
      return "As a Draft";
    default:
      return status;
  }
};

export const beautifyString = (char: string): string => {
  return char
    .replace(/_/g, " ") // Replace all underscores with spaces
    .split(" ") // Split the string into words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(" "); // Rejoin the words into a single string
};

export const formatBudgetCurrency = (value: string): string => {
  const parts = value.split(".");
  const integerPart = parts[0].replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const decimalPart = parts[1] ? `.${parts[1]}` : "";
  return `$${integerPart}${decimalPart}`;
};

export const parseFormattedCurrency = (formattedValue: string): number => {
  // Remove dollar sign, commas, and any non-numeric characters except the decimal point
  const numericString = formattedValue.replace(/[^0-9.]/g, "");
  return numericString === "" ? 0 : parseFloat(numericString);
};

export function transformInboundData(inboundData: CampaignResponseType, isDuplicate = false): CampaignState {
  const platforms: Record<string, PlatformDataType> = {};
  inboundData.platforms.forEach((platform) => {
    const timePeriods: TimePeriodsType[] = platform.time_periods.map((timePeriod) => ({
      id: timePeriod.id,
      startDate: new Date(timePeriod.start_date),
      endDate: new Date(timePeriod.end_date),
      budget: formatBudgetCurrency(timePeriod.budget.toString()),
      audiencesBudgetCustom:
        platform.budget_distribution && platform.pacing === "custom_pacing" && timePeriod.audiences
          ? timePeriod.audiences.map((audience) =>
              audience.budget !== null ? formatBudgetCurrency(audience.budget.toString()) : "",
            )
          : [],
    }));

    const audienceIds = platform.time_periods[0]?.audiences
      ? platform.time_periods[0].audiences.map((audience) => audience.id)
      : [];

    const budget = formatBudgetCurrency(platform.budget.toString());

    // Find platform name by matching platform.id with the platforms array in the state
    const platformName =
      inboundData.platforms.find((p) => p.id === platform.id)?.platform_name ?? `Platform-${platform.id}`;

    platforms[platformName] = {
      id: platform.id,
      completed: true,
      firstObjective: platform.primary_objective,
      secondObjective: {
        parentObjective: platform.primary_objective,
        objective: platform.secondary_objective,
      },
      audienceIds,
      budgetAndSchedule: {
        audienceBudgetDistribution: platform.budget_distribution,
        startDate: platform.time_periods[0]?.start_date ? new Date(platform.time_periods[0].start_date) : null,
        endDate: platform.time_periods[platform.time_periods.length - 1]?.end_date
          ? new Date(platform.time_periods[platform.time_periods.length - 1].end_date)
          : null,
        tempStartDateEven: platform.pacing === "pace_evenly" ? new Date(platform.time_periods[0]?.start_date) : null,
        tempStartDateCustom:
          platform.pacing === "custom_pacing" ? new Date(platform.time_periods[0]?.start_date) : null,
        tempEndDateEven:
          platform.pacing === "pace_evenly"
            ? new Date(platform.time_periods[platform.time_periods.length - 1]?.end_date)
            : null,
        tempEndDateCustom:
          platform.pacing === "custom_pacing"
            ? new Date(platform.time_periods[platform.time_periods.length - 1]?.end_date)
            : null,
        budget,
        tempBudgetEven: !platform.budget_distribution && platform.pacing === "pace_evenly" ? budget : "",
        tempBudgetCustom: !platform.budget_distribution && platform.pacing === "custom_pacing" ? budget : "",
        tempBudgetAudienceEven: platform.budget_distribution && platform.pacing === "pace_evenly" ? budget : "",
        tempBudgetAudienceCustom: platform.budget_distribution && platform.pacing === "custom_pacing" ? budget : "",
        pacing: platform.pacing,
        timePeriods,
        tempTimePeriods: platform.pacing === "custom_pacing" ? timePeriods : [],
        audiencesBudgetEven:
          platform.budget_distribution && platform.pacing === "pace_evenly" && platform.time_periods[0]?.audiences
            ? platform.time_periods[0].audiences.map((audience) =>
                formatBudgetCurrency(audience.budget?.toString() ?? ""),
              )
            : [],
      },
    };
  });

  const platformNames = Object.keys(platforms);
  const displayId = platformNames.length > 0 ? platformNames[platformNames.length - 1] : "";
  return {
    id: inboundData.id ? String(inboundData.id) : undefined,
    brands: [],
    platforms: [],
    audiences: [],
    step: isDuplicate ? 0 : 6,
    displayId,
    selectedBrand: String(inboundData.brand_id),
    selectedCampaignName: !isDuplicate ? inboundData?.campaign_display_name ?? "" : "",
    selectedDuplicateId: "",
    campaignCreation: platforms,
    loading: false,
  };
}

export function transformOutboundData(storeData: CampaignState, isEdit: boolean): CampaignResponseType {
  const platforms = Object.entries(storeData.campaignCreation)
    .filter(([_, platform]) => platform.completed)
    .map(([platformName, platform]) => {
      let timePeriods: TimePeriodActivationType[] = platform.budgetAndSchedule.timePeriods.map((timePeriod) => {
        const periodBudget = platform.budgetAndSchedule.audienceBudgetDistribution
          ? timePeriod.audiencesBudgetCustom.reduce(
              (sum, audienceBudget) => sum + parseFormattedCurrency(audienceBudget),
              0,
            )
          : parseFormattedCurrency(timePeriod.budget);

        return {
          id: isEdit ? timePeriod.id : undefined,
          start_date: (timePeriod.startDate as Date).toISOString().split("T")[0],
          end_date: (timePeriod.endDate as Date).toISOString().split("T")[0],
          budget: periodBudget,
          audiences: platform.audienceIds.map((audienceId, index) => ({
            id: audienceId,
            budget: platform.budgetAndSchedule.audienceBudgetDistribution
              ? platform.budgetAndSchedule.pacing === "custom_pacing"
                ? parseFormattedCurrency(timePeriod.audiencesBudgetCustom[index])
                : parseFormattedCurrency(platform.budgetAndSchedule.audiencesBudgetEven[index])
              : null,
          })),
        };
      });

      // Ensure time_periods array includes at least one object with start_date and end_date
      if (timePeriods.length === 0) {
        timePeriods = [
          {
            id: undefined,
            start_date: (platform.budgetAndSchedule.startDate as Date).toISOString().split("T")[0],
            end_date: (platform.budgetAndSchedule.endDate as Date).toISOString().split("T")[0],
            budget: parseFormattedCurrency(platform.budgetAndSchedule.budget),
            audiences: platform.audienceIds.map((audienceId, index) => ({
              id: audienceId,
              budget: platform.budgetAndSchedule.audienceBudgetDistribution
                ? parseFormattedCurrency(platform.budgetAndSchedule.audiencesBudgetEven[index])
                : null,
            })),
          },
        ];
      }

      const totalBudget = parseFormattedCurrency(platform.budgetAndSchedule.budget);

      // Find the corresponding platform object in storeData.platforms by platformName
      const platformData = storeData.platforms.find((p) => p.name === platformName);

      return {
        id: isEdit ? platform.id : undefined,
        platform_id: platformData?.id ?? null,
        primary_objective: platform.firstObjective.split(" ")[0],
        secondary_objective: platform.secondObjective.objective.split(" ")[0],
        pacing: platform.budgetAndSchedule.pacing,
        budget: totalBudget,
        budget_distribution: platform.budgetAndSchedule.audienceBudgetDistribution,
        time_periods: timePeriods,
      };
    });

  return {
    id: isEdit ? storeData.id : undefined,
    name: storeData.selectedCampaignName,
    brand_id: storeData.selectedBrand,
    platforms,
  };
}
